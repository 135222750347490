/**
 * Bootstrap Fonts
 */

@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('../bower_components/bootstrap/fonts/glyphicons-halflings-regular.eot');
    src: url('../bower_components/bootstrap/fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
    url('../bower_components/bootstrap/fonts/glyphicons-halflings-regular.woff') format('woff'),
    url('../bower_components/bootstrap/fonts/glyphicons-halflings-regular.ttf') format('truetype'),
    url('../bower_components/bootstrap/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

/**
 *Font Awesome Fonts
 */

@font-face {
    font-family: 'FontAwesome';
    src: url('../bower_components/font-awesome/fonts/fontawesome-webfont.eot?v=4.1.0');
    src: url('../bower_components/font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=4.1.0') format('embedded-opentype'),
    url('../bower_components/font-awesome/fonts/fontawesome-webfont.woff?v=4.1.0') format('woff'),
    url('../bower_components/font-awesome/fonts/fontawesome-webfont.ttf?v=4.1.0') format('truetype'),
    url('../bower_components/font-awesome/fonts/fontawesome-webfont.svg?v=4.1.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/**
 * App-wide Styles
 */

/*NUEVO DISENO -> XPECTRA-WHITE*/

.logo {
    height: auto !important;
}

.login-page, .register-page {
    /* background: #d2d6de; */
    background-color: transparent;
}

.logo-lg img, .logo-mini img {
    max-height: 60px;
    display: inline-block;
    position: relative;
    margin-top: 5px;
}

.xpectra-logo path {
    fill: #fff !important;
    /*fill: red;*/
}

.logo-mini img {
    max-height: 40px;
    display: inline-block;
}

.col-height-md {
    height: 10px;
}

/*.navbar-nav>.user-menu>.dropdown-menu>li>span.user-header{*/
/*height: 30px;*/
/*padding: 0px;*/
/*color: #005C9C;*/
/*}*/

.skin-blue .main-header .navbar .nav > li > a {
    /*color: #ffffff;*/
    color: #00529c;
}

.skin-blue .main-header .logo {
    position: relative;
    background: #F2F2F2 !important;
    /*background: #00529c !important;*/
}

/*NAVBAR-TOP*/

.main-header {
    height: 70px;
}

.hr-client {
    border-bottom: 4px solid #005C9C;
}

.hr-default {
    border-bottom: 4px solid transparent;
}

.hr-technician {
    border-bottom: 4px solid #ff6600;
}

.skin-blue .main-header .navbar {
    /*background: #00529c !important;*/
    background: #F2F2F2 !important;
}

.skin-blue .main-header li.user-header {
    background: #00529c !important;

}

/* <-- FIN NUEVO DISEÃ‘O -> XPECTRA-WHITE*/

.browsehappy {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

.hideme {
    display: none;
}

a {
    text-decoration: none !important;
}

.animate-if.ng-enter, .animate-if.ng-leave {
    transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
}

.animate-if.ng-enter,
.animate-if.ng-leave.ng-leave-active {
    opacity: 0;
}

.animate-if.ng-leave,
.animate-if.ng-enter.ng-enter-active {
    opacity: 1;
}

.spinner {
    -webkit-animation: rotator 1.4s linear infinite;
    animation: rotator 1.4s linear infinite;
}

@-webkit-keyframes rotator {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
}

@keyframes rotator {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
}

.path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
    animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@-webkit-keyframes colors {
    0% {
        stroke: #4285F4;
    }
    25% {
        stroke: #DE3E35;
    }
    50% {
        stroke: #F7C223;
    }
    75% {
        stroke: #1B9A59;
    }
    100% {
        stroke: #4285F4;
    }
}

@keyframes colors {
    0% {
        stroke: #4285F4;
    }
    25% {
        stroke: #DE3E35;
    }
    50% {
        stroke: #F7C223;
    }
    75% {
        stroke: #1B9A59;
    }
    100% {
        stroke: #4285F4;
    }
}

@-webkit-keyframes dash {
    0% {
        stroke-dashoffset: 187;
    }
    50% {
        stroke-dashoffset: 46.75;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187;
        -webkit-transform: rotate(450deg);
        transform: rotate(450deg);
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 187;
    }
    50% {
        stroke-dashoffset: 46.75;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187;
        -webkit-transform: rotate(450deg);
        transform: rotate(450deg);
    }
}

.loader {
    position: relative;
    margin: 0px auto;
    width: 50px;
    /*height: 50px;*/
    zoom: 1.3;
}

.loader2 {
    position: relative;
    margin: 0px auto;
    width: 100px;
    /*height: 50px;*/
    zoom: 1.3;
}

.circular {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 50px;
    position: relative;
    width: 50px;
}

.circular2 {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100px;
    position: relative;
    width: 100px;
}

.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}

@-webkit-keyframes color {
    100%, 0% {
        stroke: #00529c;
    }
    /*40% {
      stroke: #0057e7;
    }
    66% {
      stroke: #008744;
    }
    80%, 90% {
      stroke: #ffa700;
    }*/
}

@keyframes color {
    100%, 0% {
        stroke: #00529c;
    }
    /*40% {
      stroke: #0057e7;
    }
    66% {
      stroke: #008744;
    }
    80%, 90% {
      stroke: #ffa700;
    }*/
}

table {
    width: 100% !important;
    text-align: center;
}

th {
    text-align: center;
}

.nav.nav-tabs li {
    opacity: .7;
}

.nav.nav-tabs li.active {
    opacity: 1;
}

/*TAB PRINCIPAL*/

.tab-content {
    /*border:2px solid #7F7F7F;*/
    border-top-color: transparent;
}

.tab-content .tab-pane {
    border: 1px solid #d1d1d1;
    max-width: 100% !important;
    padding: 1em;
    background: white;
    border-top-color: transparent;
}

.tabs {
    padding: 5px;
}

.tab-content .tab-pane h3 {
    display: inline-block;
}

/*TABS -ESTILO TITULO*/

.nav-tabs {
    background: #f7f7f7;
}

.nav-tabs > li {
    padding: 0px;
    background: #f7f7f7;
}

.nav-tabs > li > a uib-tab-heading {
    font-size: 120%;
}

.nav-tabs > li.active > a uib-tab-heading {
    font-weight: bold;
    font-size: 130%;
}


.ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li.active > a{
    color: white !important;
    border: 1px solid #d1d1d1 !important;
    background-color: #00529c !important;
}

/*FIN TABS -ESTILO TITULO*/

/*FORMULARIOS */
.checkbox-slider--b-flat.byside-right label span {
    padding-left: 55px;
}

label, input, select {
    cursor: pointer;
}

fieldset {

    border: 1px solid #d1d1d1;
    border-radius: .3em;
    padding: 0 2.5em 2.5em 2.5em;
    margin: 2em .5em .5em .5em;
}

fieldset fieldset {
    margin: .5em 1em 1em 1em;
}

fieldset fieldset.child-not-fieldset {
    margin: .5em 0;
    padding: 0;
    border: 0;
}

fieldset legend {
    padding-left: .2em;
    padding-right: .5em;
    border: 0;
    width: auto;
    margin-top: -2.5em;
}

.checkbox label:first-of-type {
    margin-left: 0;
}

.checkbox.horizontal label {
    margin-left: 1em;
}

.checkbox.horizontal label:first-of-type {
    margin-left: .5em;
}

.checkbox input[type=checkbox] {
    border: 1px solid #d1d1d1;
    padding: .5em;
}

.checkbox input[type=checkbox]:checked {
    background: black;
}

.form-actions-bottom, .nav-actions-bottom {
    text-align: right;
    margin: 1em;
}

.form-actions-center .btn, .nav-actions-center .btn {
    margin-right: 8px;
}

.form-actions-center, .nav-actions-center {
    text-align: right;
    margin: 1em;
    display: block;
}

.nav-actions-center {
    margin-right: 0 !important;
}

.form-actions-top, .nav-actions-top {
    text-align: right;
    margin: 0 1em 1em 1em;
}

.form-actions-top a, .form-actions-top button, .form-actions-bottom a, .form-actions-bottom button {
    text-transform: uppercase;
}

div.row.form-schedule .form-schedule-day {
    margin-bottom: 1.8em;
}

.form-schedule .vds-multirange-mk2-container {
    height: auto;
}

.form-schedule .vds-multirange-mk2-labels-container {
    display: none;
}

/*FIN DE FORMULARIOS*/

/* BOTONES / BUTTONS */
.form-actions-bottom .btn {
    text-transform: uppercase;
}

/*.btn {
  !*color: white;*!
  color: #7F7F7F;
}*/

.btn:hover {
    opacity: .8;
    /*box-shadow: inset 0px 0px 15px rgba(0,0,0, .1);*/
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    /* color: white;*/
}

.btn:focus {
    box-shadow: inset 0 0 20px rgba(43, 43, 43, .1);
    color: #7F7F7F;
}

.btn i {
    margin-right: .2em;
}

.btn-help {
    background: none;
    color: #CCC;
    border: none;
    opacity: .9;
    margin-left: .2em;
}

.btn-help i {
    /*color: #e1ae5b;*/
    color: #CCC !important;;
    font-size: 10px;
}

.btn-back, .btn-forward {
    background-color: #dddddd;
    border-color: #dddddd;
    color: #555 !important;
}

.btn-new {
    background-color: #2cb76b;
    border-color: #2cb76b;
    color: white !important;;
}

.btn-save, .btn-update, .btn-success {
    background-color: #2cb76b;
    border-color: #2cb76b;
    color: white !important;
}

.btn-edit, .btn-search {
    background-color: #335b7e;
    border-color: #335b7e;
    color: white !important;
}

.btn-erase {
    background-color: #d20417;
    border-color: #d20417;
    color: white !important;
}

.btn-reset {
    background-color: #555;
    border-color: #555;
    color: white !important;
}

.btn-cancel {
    background-color: #fc4128;
    border-color: #fc4128;
    color: white !important;
}

.btn-default, .btn-dropdown, .btn-config {
    background-color: #dddddd !important;
    border-color: #dddddd !important;
    color: #555 !important;
}

.btn.btn-default:hover, .btn.btn-dropdown:hover {
    background-color: #dddddd;
    border-color: #dddddd;
    color: #555 !important;
}

.btn.btn-config:hover {
    background-color: #dddddd;
    border-color: #dddddd;
    color: #555 !important;
}

/* FIN DE BOTONES */

/*CSS DATATABLES */

table.dataTable .btn {
    padding: 0 1px;
    margin: 1px;
    text-align: center;
}

table.dataTable .btn-group .btn {
    padding: 0;
    margin: 1px;
    text-align: center;
}

table.dataTable {
    border: 1px solid #CCC;
    margin-top: 10px;
}

table.dataTable thead {
    background-color: #e0e0e0;
}

table.dataTable.no-footer {
    border: 1px solid #e0e0e0;
}

table.dataTable thead th, table.dataTable thead td {
    padding: 10px 18px;
    border-bottom: 1px solid #CCC;
}

table.dataTable thead tr th {
    vertical-align: middle;
    text-align: center;
    border-right: 1px solid #CCC;
    padding: 5px 10px;
}

table.dataTable thead {
    min-height: 300px;
}

table.dataTable thead tr th:last-of-type {
    border-right: none;
    min-width: 65px;
}

table.dataTable thead tr {
    border: 1px solid #d1d1d1;
}

table.dataTable tr td {
    width: 50px;
    vertical-align: middle;
}

table.dataTable tr td:first-child {
    text-align: center;
}

table.dataTable tr td:last-child {
    vertical-align: middle;
}

table.dataTable .btn-danger {
    padding: 0 4px;
}

.table-striped .btn {
    padding: 0 4px;
}

.table-striped .btn-danger {
    padding: 0 6px;
}

table.dataTable tbody td {
    padding: 0px 0px;
}

.dataTables_paginate .last {
    opacity: 1;
}

/*FIN DE DATATABLES*/

@media (min-width: 768px) {
    .skin-blue .wrapper, .skin-blue .main-sidebar, .skin-blue .left-side {
        background-color: #F2F3F2;
        /*background-color: #e1e1e1;*/
    }

    .sidebar {
        padding-bottom: 0;
    }

    .main-sidebar, .left-side {
        width: 220px;
    }

    /*ARBOL (LADO IZQUIERDO)*/
    .main-sidebar .sidebar {
        margin-top: 23px;
        background: #F2F3F2;
        height: 476.99px;
        /*height: 120px;*/
        /*margin-right: .7em;*/
        /*border-top: 1px solid #d1d1d1;*/
        /*border-right: 1px solid #d1d1d1;*/
        /*border-bottom: 1px solid #d1d1d1;*/
    }

    /*INICIO DE ESTILO DE ICONOS DE LA PARTE SUPERIOR DERECHA*/
    .icon-Header {
        color: #005C9C !important;
        line-height: 36px !important;
        margin-right: 20px;
        text-align: right;
        cursor: pointer;
    }

    .notify {
        float: right;
        width: 250px;
        margin-right: 5px;
        margin-left: 5px;
        margin-bottom: -4px;
        text-align: right;
    }

    .Usuario {
        margin-top: -8px;
        margin-left: 10px;
        margin-right: -5px;
        font-size: 12px;
        color: #005C9C;
        background: #F2F2F2 !important;
    }

    .navbar-static-top {
        padding: 0px;
        margin-bottom: -10px;
    }

    .navbar-custom-menu {
        width: 150px;
    }

    .user-height {
        float: right;
    }

    .user-image {
        float: left;
        width: 25px;
        height: 25px;
        margin-bottom: -40px;
    }

    .hidden-xs {
        float: right;
        margin-left: 10px;
    }

    /*FIN DE ESTILO DE ICONOS DE LA PARTE SUPERIOR DERECHA*/
    /*INICIO DE DROPDOWN-MENU*/
    /*<!--width: 195px;-->*/
    .dropdown-menu {
        width: 250px;
    }

    .open > .dropdown-menu {
        background: #F2F2F2 !important;
        /*border: 2px solid #7F7F7F;*/
    }

    .dropdown-item {
        margin-left: 10px;
        color: #7F7F7F;
        cursor: pointer;
    }

    .hr-menu-min {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    /*FIN DE DROPDOWN-MENU*/
    hr {
        margin-top: 0px;
        margin-bottom: 0px;
        border: 0;
        border-top: 1px solid #7F7F7F;
    }

    /*INICIO DEL CONTENEDOR DEL TAB DE LAS ALARMAS*/
    .account-data {
        margin-top: -30px;
    }

    .account-data .account-type {
        height: 30px;
    }

    .central-data hr {
        margin-top: 25px;
        border-top: 1px solid #7F7F7F;
    }

    .central-data h2 {
        color: black;
        font-size: 18px;
        cursor: pointer;
    }

    .central-data h2 .iconos {
        /*color: #FF0000;*/
        font-size: 30px;
    }

    .central-data .form-group .alarms {
        width: 100%;
        margin-top: 10px;
    }

    .central-data .account-type {
        height: 20px;
        float: left;
        margin-left: 60%;
        /*margin-top: 6px;*/
        position: relative;
        background-color: transparent;
        color: red;
    }

    .central-data .img-account-type {
        padding: 0px;
    }

    .central-data .inf-ubication {
        padding: 0px;
    }

    .central-data .pull-right {
        color: #A6A6A6;
        margin-top: 10px;
        font-size: 18px;
    }

    .central-data .form-group .alarms .pull-right {
        color: #A6A6A6;
        margin-top: 0px;
        font-size: 100%;
        float: right;
    }

    .central-data .alarm-ubication {
        margin-left: 5px;
        color: #A6A6A6;
        /*  margin-left: -60px;*/
        float: left;
        position: relative;
    }

    .alarmsListContainer {
        margin: 0px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 5px;
    }

    /*FIN DEL CONTENEDOR DE LAS ALARMAS*/
    /*INICIO DE ESTILO DE TAB DE CUENTAS*/
    .centroM hr {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 0px;
        margin-bottom: 5px;
        background: #BFBFBF;
    }

    .centroM .infocm {
        margin: 0px;
        padding: 0px;
        padding-right: 0px;
        width: 7%;
    }

    .centroM .infcm-ubi {
        padding: 0px;
    }

    .centroM .infocm .imgCM {
        display: block;
        max-width: 90%;
        height: 70px;
        margin-top: 0px;
        margin-left: 15px;
        padding-right: 5px;
        margin-right: 7px;
    }

    .centroM .infcm-ubi hr {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 0px;
        margin-right: 10px;
    }

    .centroM h3 {
        color: black;
        margin: 0px;
        margin-left: 10px;
    }

    .centroM p {
        color: black;
        margin-top: 0px;
        margin-left: 10px;
        margin-bottom: 0px;
    }

    .centroM .contact {
        color: black;
    }

    .accountInfo .nameAccount {
        width: 100%;
        margin-top: 10px;
        cursor: pointer;
        margin-bottom: 4px;
    }

    .accountInfo hr {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 7%;
        margin-right: 10px;
    }

    .accountInfo .nameAccount .img-account-type .account-type {
        height: 27px;
        float: left;
        margin-right: 5%;
        margin-left: 120%;
        margin-top: 6px;
        /*position: relative;*/
    }

    .accountInfo .nameAccount .img-account-type .statusPanel {
        height: 27px;
        float: left;
        margin-right: 10px;
        margin-left: 120%;
        margin-top: 0px;
        position: relative;
        background: blue;
    }

    .statusPanel {
        color: #A6A6A6;
    }

    /*.statusPanel{*/
    /*margin-left: 10px;*/
    /*max-width: 140%;*/
    /*}*/
    .accountInfo {
        margin-top: -10px;
    }

    .dataAccount {
        margin-top: 2px;
    }

    .accountInfo .nameAccount .inf-name .name-account {
        margin-left: 18px;
        font-size: 18px;
        margin-top: 4px;
        margin-bottom: 0px;

    }

    .accountInfo .inf-name a {
        color: #A6A6A6;
        margin-top: 5px;
        margin-left: 5px;
    }

    .accountInfo .nameAccount .inf-name a {
        color: #A6A6A6;
        margin-left: 2%;
        margin-top: 0px;
        width: auto;
    }

    .accountInfo .dataAccount {
        width: 100%;
        margin-top: 0px;
    }

    .accountInfo .dataAccount .img-account-type .account-type {
        height: 30px;
        width: 20px;
        float: left;
        margin-right: 5px;
        margin-left: 120%;
        position: relative;
        margin-top: -5px;
    }

    .accountInfo .dataAccount .img-account-type .armed {
        height: 30px;
        width: 20px;
        float: left;
        margin-right: 5px;
        margin-left: 120%;
        position: relative;
        margin-top: -10px;
    }

    .icon-ico-apertura:before {
        font-size: 25px;
        color: green;
    }

    .icon-ico-cierre-parcial:before {
        font-size: 20px;
        color: red;
        margin-left: 5px;
    }

    .accountInfo .dataAccount .inf-name {
        margin-top: 0px;
    }

    .accountInfo .dataAccount .inf-name p {
        color: #A6A6A6;
        margin-left: 16px;
        margin-top: 10px;
    }

    .accountInfo .nameAccount .inf-name p {
        color: black;
        margin-left: 16px;
        margin-top: 10px;
    }

    /*FIN DE ESTILO DE TAB DE CUENTAS*/
    /*Estilos de Soporte (Solicitudes y Tickets */
    .supportInfo .nameAccount {
        width: 100%;
        margin-top: 10px;
        cursor: pointer;
    }

    .supportInfo hr {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 7%;
        margin-right: 2%;
    }

    .supportInfo .nameAccount .img-account-type .account-type {
        height: 27px;
        width: 25px;
        float: left;
        margin-right: 5px;
        margin-top: 0px;
    }

    .row-title {
        padding-left: 0;
    }

    .row-title p {
        font-size: 18px;
        color: black;
    }

    .row-title i {
        font-size: 27px;
        margin-right: 5px;
        color: #A6A6A6;
    }

    .row-title a {
        font-size: 14px;
        color: #A6A6A6;
    }

    .row-title span {
        font-size: 14px;
        color: #A6A6A6;
    }

    /*-------------- Fin -----------*/
    /*INICIO DE ESTILO DE MODALES*/
    .modal-cloud {
        font-family: Century Gothic, sans-serif;
        border: 5px solid #7F7F7F;
        border-radius: 5px;
    }

    .modal-content{
        border-radius: 10px !important;
    }

    .modal-cloud .modal-header {
        background-color: #7F7F7F;
        color: white;
        height: 50px;
    }

    .modal-cloud .modal-header h3 {
        font-size: 120%;
        margin-top: 0px;
    }

    .modal-cloud .modal-footer {
        text-align: center;
        margin-top: 20px;
    }

    .modal-cloud .modal-body {
        padding-top: 15px;
        color: black;
    }

    /*INICIO DEL ENCABEZADO DE LOS MODALES -INFORMACION DEL CENTRO*/
    .modal-cloud .modal-body .info-cm {
        margin-top: 0px;
        background-color: #F2F3F2;
        height: 100px;
    }

    .modal-cloud .modal-body .info-cm img {
        margin-top: 5px;
        margin-bottom: 5px;
        width: 150%;
        height: 90px;
    }

    .modal-cloud .modal-body .info-cm p {
        margin: 0px;
        font-size: 14px;
    }

    .modal-cloud .modal-body .info-cm h3 {
        margin-top: 15px;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 120%;
    }

    .modal-cloud .modal-body .info-cm a > i {
        margin-left: 25px;
    }

    .modal-cloud .modal-body hr {
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .modal-cloud .modal-body .element i {
        font-size: 30px;
        margin-left: 15px;
    }

    .modal-cloud .modal-body .element .help i {
        font-size: 15px;
        margin-left: 0px;
    }

    .modal-cloud .modal-body .lista i {
        font-size: 15px;
        margin-left: 0px;
    }

    .modal-cloud .modal-body .info-image {
        margin-top: 0px;
        height: 100px;
        width: 100%;
    }

    .zoom {
        /* Aumentamos la anchura y altura durante 2 segundos */
        transition: width 2s, height 2s, transform 2s;
        -moz-transition: width 2s, height 2s, -moz-transform 2s;
        -webkit-transition: width 2s, height 2s, -webkit-transform 2s;
        -o-transition: width 2s, height 2s, -o-transform 2s;
    }

    .zoom:hover {
        /* tranformamos el elemento al pasar el mouse por encima al doble de
           su tamaño con scale(2). */
        transform: scale(2);
        -moz-transform: scale(2); /* Firefox */
        -webkit-transform: scale(2); /* Chrome - Safari */
        -o-transform: scale(2); /* Opera */
    }

    /*FIN DEL ENCABEZADO DE LOS MODALES -INFORMACION DEL CENTRO*/
    /*INCIO DEL ESTILO DEL MODAL DE DETALLES DE CUENTAS*/
    .modal-cloud .modal-body .element .alarms-name h2 {
        font-size: 20px;
        margin-bottom: 0px;
        margin-left: 2px;
        margin-top: 0px;
    }

    .modal-cloud .modal-body .account-ubication p {
        font-size: 14px;
        color: #A6A6A6;
    }

    .modal-cloud .modal-body .info-fact p {
        font-size: 14px;
        color: black;

    }

    .modal-cloud .modal-body .account-ubication .info-fact .account-typeIcon {
        height: 20px;
        width: 30px;
    }

    .alarms-name i {
        /*color: #A6A6A6;*/
    }

    /*FIN DEL ESTILO DEL MODAL DE DETALLES DE CUENTAS*/
    /*INCIO DEL ESTILOS DEL MODAL DE ALARMAS*/
    .modal-cloud .modal-body .element .alarms-name h2 {
        font-size: 20px;
        margin-bottom: 0px;
        margin-left: 2px;
        margin-top: 0px;
    }

    .modal-cloud .modal-body .element .localizacion i {
        font-size: 30px;
        margin-left: 12px;
        margin-top: 5px;
        color: #7F7F7F;
    }

    .modal-cloud .modal-body .element .alarms-name p {
        font-size: 14px;
        margin-bottom: 0px;
        margin-left: 2px;
        margin-top: 5px;
        color: #A6A6A6;
    }

    .modal-cloud .modal-body .element .plan {
        margin-top: 5px;
    }

    .modal-cloud .modal-body .element .app p {
        font-size: 14px;
        margin-bottom: 0px;
        margin-left: 2px;
        margin-top: 4px;
        color: #A6A6A6;
    }

    .modal-cloud .modal-body .element .ubication-panico p {
        font-size: 14px;
        margin-bottom: 0px;
        margin-left: 2px;
        margin-top: 0px;
        color: black;
    }

    .modal-cloud .modal-body .element .plan p {
        font-size: 14px;
        margin-bottom: 0px;
        margin-left: 2px;
        margin-top: 0px;
        color: black;
    }

    /*FIN DE ESTILO DEL MODAL DE ALARMAS*/
    .modal-cloud .modal-body .element .time {
        margin-top: 5px;
    }

    .modal-cloud .modal-body .element .account-type {
        height: 25px;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 3px;
        float: left;
        position: relative;
    }

    .modal-cloud .modal-body .element .account-type-account {
        height: 25px;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 13px;
        float: left;
        position: relative;
    }

    .modal-cloud .modal-body .element .alarms .alarm-ubication {
        float: left;
        margin-top: 5px;
        position: relative;
    }

    .modal-cloud .modal-body .element .pull-left {
        float: right;
        position: relative;
    }

    .modal-cloud .modal-body .element .alarms-name {
        margin-top: 10px;
    }

    .modal-cloud .modal-body .alarms .inf-ubication .n-account {
        float: right;
        position: relative;
        margin: 0px;
        padding-right: 15px;
    }

    .modal-cloud .modal-body .inf-ubication p {
        margin-left: 20px;
        margin-top: 3px;
        margin-bottom: 0px;
    }

    .modal-info {
        margin-top: 10px;
    }

    /*FIN DE ESTILO DE MODALES*/
    /*.central-data  .account-type .alarm-ubication{*/
    /*height: 30px;*/
    /*float: left;*/
    /*margin-left: -120px;*/
    /*}*/
    .content-wrapper {
        margin-top: .2em;
        margin-left: 220px;
        border: 3px solid #7F7F7F;
        background: #f7f7f7;
    }

    section.content-header {
        background: #f9f9f9 !important;
        /*padding: 15px 15px 10px 15px;*/
        padding: 0 !important;
        border-bottom: 1px solid #d1d1d1;
    }

    .skin-blue .sidebar-menu > li.header {
        color: #040404;
        background: #f9f9f9;
        text-align: center;
        border-bottom: 1px solid #d1d1d1;
        padding-top: 1.1em;
        padding-bottom: 1.1em;
        text-transform: uppercase;
    }

    .skin-blue .sidebar-menu > li > .treeview-menu {
        background: #f9f9f9;
    }

    .skin-blue .sidebar-menu > li > a {
        border-left: 8px solid white;
        border-right: 3px solid transparent;
        border-bottom: 1px solid #efefef;
        border-top: 1px solid #f5f5f5;
        background: #F2F2F2;
        /*background: #f9f9f9;*/
    }

    /*SELECCIÓN DEL MENU*/
    .treeview i {
        border: 3px;
        font-size: 25px;
    }

    .skin-blue .sidebar-menu > li:hover > a, .skin-blue .sidebar-menu > li.active > a {
        /*background: #00529c;*/
        color: white;
        background: #00529c !important;
        border-left: 8px solid white;
        border-right-color: white !important;
    }



    .skin-blue .sidebar a {
        color: #757575;
        /*text-transform: uppercase;*/
        font-size: 100%;
    }

    /*FIN DE SELECCIÓN DEL MENU*/
    .skin-blue .treeview-menu > li > a {
        color: #8c908f;

        font-size: 90%;
    }

    .skin-blue .treeview-menu > li > a:hover {
        color: #757575;
    }

    .content-header > .breadcrumb {
        position: initial;
        margin-right: 1em;
        padding-bottom: 1em;
        padding-top: 1em;
    }

    .content-header > h1 {
        font-size: 1em;
        text-transform: uppercase;
        margin: 0;
        padding: 1.1em 25px 1.1em 15px;
        color: #4b4b4b;
        font-weight: normal;
        opacity: .8;
        /*letter-spacing: .01em;*/
        letter-spacing: .1em;
        display: inline-block;
        line-height: 1em;
        vertical-align: middle;
        /*background: url("/images/header-arrow-bg.png") center right no-repeat;*/
    }

    .margin-content-tab {
        margin: 1em;
    }

    /*ESTILOS DE LOS ICONOS*/
    /*.central-data .icon-ico-alarmas{*/
    /*color: #FF0000;*/
    /*font-size: 20px;*/
    /*}*/
    uib-tab-heading .icon-ico-alarmas {
        color: #005C9C;
        font-size: 20px;
    }

    .btn .icon-ok {
        width: 30px;
    }

    .icon-ico-apps-panico:before {
        font-size: 20px;
        color: #000;
    }

    .main-footer {
        border: 0;
        text-align: center;
        font-weight: normal;
        width: 100%;
        margin-left: 0px;
        background: #00529c
    }

    .footer-client {
        background: #00529c;
    }

    .footer-technician {
        background: #ff6600;
    }

    .footer-default {
        background: transparent;
    }

    .text-footer {
        color: white;
        font-weight: bold;
    }

    #map_canvas {
        height: 500px;
        margin-left: 1em;
        margin-right: 1em;
        background-color: #f7f7f7;
    }

    .full_map {
        height: 500px;
        margin-left: 1em;
        margin-right: 1em;
        background-color: #f7f7f7;
    }

    /*FIN ESTILOS DE LOS ICONOS*/
    /*INICIO DE ESTILO DE FACTURACIÓN*/
    .centroM-billing {
        margin-left: 10px;
    }

    .centroM-billing .infocm {
        margin: 0px;
        padding: 0px;
        padding-right: 0px;
        width: 7%;
    }

    .inf-name-receipts p {
        margin-left: -15px;
        margin-top: 0px;
        font-size: 18px;
        margin-bottom: 4px;
    }

    .accountInfo-receipt .dataAccount .img-account-type .account-type {
        height: 30px;
        width: 20px;
        float: left;
        margin-right: 5px;
        margin-left: 120%;
        position: relative;
        margin-top: 2px;
    }

    .total-receipts {
        margin-top: 0px;
    }

    .totalizacion {
        width: 140px;
    }

    .totalizacion > hr {
        margin-top: -30px;
    }

    .input-group {
        margin-left: 40px;
    }

    .newMethod {
        text-align: center;
    }

    .centroM-billing .infocm .imgCM {
        display: block;
        max-width: 120%;
        height: 70px;
        margin-top: 4px;
        margin-left: 0px;
    }

    .accountInfo .nameAccount {
        width: 100%;
        margin-top: 10px;
        cursor: pointer;
        margin-bottom: 4px;
    }

    .accountInfo .nameAccount .input-group-addon {
        margin-right: 5px;
        margin-left: 5px;
        text-align: right;
    }

    .accountInfo .nameAccount hr {
        margin-top: -20px;
    }

    .centroM-billing .infcm-ubi-billing h3 {
        margin-top: 0px;
    }

    .payment-method {
        margin-right: -20px;
    }

    .total {
        position: relative;
        margin-bottom: 10px;
    }

    .total-cuenta {
        margin-top: 10px;
    }

    .pay-all {
        margin-top: -10px;
        margin-right: -20px;
        margin-left: 15px;
        position: relative;
    }

    .payment-account {
        margin-top: -10px;
        margin-left: 10px;
        margin-bottom: 10px;
    }

    .inf-billing p {
        margin-top: 0px;
        margin-left: 5px;
        font-size: 18px;
        margin-bottom: -4px;
        cursor: pointer;
    }

    .inf-billing a {
        color: #A6A6A6;
        margin-top: 0px;
    }

    .accountInfo .nameAccount .receipt p {
        font-size: 18px;
        margin-bottom: 0px;
    }

    .accountInfo .nameAccount .receipt a {
        margin-top: 0px;
        color: #A6A6A6;
    }

    .hr-billings {
        margin-top: 10px;
    }

    .billingsListContainer {
        margin: 0px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 5px;
    }

    .title {
        font-weight: bold;
        font-size: 16px;
    }

    .number-receipt {
        font-weight: normal;
        font-size: 16px;
    }

    .date {
        margin-top: -5px;
    }

    .receipt-account {
        margin-bottom: 0px;
    }

    .subtotal {
        font-size: 16px;
        margin-right: 40px;
    }

    .total {
        font-size: 20px;
        margin-right: 0px;
    }

    .total1 {
        font-size: 20px;
        margin-right: -100px;
    }

    .totalPagar {
        margin-top: 10px;
        margin-right: 20px;
        font-size: 18px;
    }

    .tag-item-list {
        background: #DDDDDD !important;
        margin-top: 15px;
        display: inline-block;
        line-height: 25px;
        border: 1px solid #DDDDDD;
        border-radius: 3px;
    }

    /*FIN DE ESTILO DE FACTURACIÓN*/
    /* FIN DE NUEVO DISEÑO -> XPECTRA-WHITE*/
    /*.sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span:not(.pull-right), */
    /*.sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {*/
    /*display: block!important;*/
    /*position: absolute;*/
    /*width: 220px;*/
    /*left: 41px;*/
    /*border: 1px solid #CCC;*/
    /*margin-left: -1px;*/
    /*}*/
    /*.sidebar-mini.sidebar-collapse .sidebar-menu > li > a {*/
    /*padding-left: 12px;*/
    /*}*/
    /*.sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span {*/
    /*background: #00529c!important;*/
    /*color: white;*/
    /*/!*border: 2px solid #000;*!/*/
    /*}*/
    /*.sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>a {*/
    /*border-right: 0!important;*/
    /*}*/
    /*.skin-blue .sidebar-menu>li>a .icon {*/
    /*font-size: 150%;*/
    /*}*/

}

/*VALIDACION DE FORMULARIOS*/
.img-file-upload {
    display: block;
    max-height: 225px;
    max-width: 100%;
    height: auto;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

}

.ui-select-container .btn.btn-default:not([disabled]) {
    background: #FFF !important;
}

.btn-link {
    padding: 0px;
}

textarea {
    resize: vertical;
}

.input-element {
    margin: 0;
}

div.loading-app img {
    max-width: 50%;
    padding-left: 10%;
    padding-right: 10%;
    position: absolute;
    margin: auto;
    left: 0;
    top: 10%;
    right: 0;
}

div.loading-app {

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    z-index: 8888888;
    background-color: #ffffff;

}

svg#main-loading circle {
    fill: none;
    stroke: #355989;
    stroke-linecap: round;
    stroke-width: 7;
    -webkit-animation: circley 8s ease infinite, rotaty 2.2s linear infinite;
    -moz-animation: circley 8s ease infinite, rotaty 2.2s linear infinite;
    transform-origin: center center;
}

svg#main-loading circle.colored {
    -webkit-animation: dash 0.9s ease infinite, colors 5.6s ease-in-out infinite;
    -moz-animation: circley 8s ease infinite, rotaty 2.2s linear infinite, colored 5.3333s ease infinite;

}

svg#main-loading circle.colored.slow-as-fuck {
    -webkit-animation: circley 216s ease infinite, rotaty 59.4s linear infinite, colored 144s ease infinite;
    -moz-animation: circley 216s ease infinite, rotaty 59.4s linear infinite, colored 144s ease infinite;
}

.icon {
    width: 25px;
    height: 25px;
}

.dropdown-container {
    position: relative;
}

.switch {
    width: 35px;
    height: 20px;
    background: #dadada;
}

.switch small {
    border-radius: 100%;
    width: 20px;
    height: 20px;
}

.switch.checked small {
    left: 15px;
}

.switch:focus {
    outline: none;
}

.frame {
    border: 1px solid #ddd !important;
    xmin-width: 0;
    padding: 10px;
    position: relative;
    border-radius: 4px;
    background-color: #f5f5f5;
    padding-left: 10px !important;
}

.title-bordered {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0px;

    border: 1px solid #ddd !important;
    border-radius: 4px;
    padding: 5px 5px 5px 10px !important;
    background-color: #ffffff;
}



.main-header{
    background-color:#00529c !important;
}


.no-data{
    text-align: center;
    font-weight: bolder;
    padding-top: 20px;
}

.no-data img{
    width: 50px;

}


.ui-tabs-scrollable > .spacer > div:first-child > .tab-content > .active{
    overflow: visible !important;
}