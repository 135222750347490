
.login-page {
  /*background-color: white;*/
  background-color: #F2F3F2;
}

.login-page .bg-login-animate {
  background-image: url("/images/Nevula-Fondo (2).png");
  background-position-x: 105%;
  background-position-y: center;
  -webkit-background-size: auto;
  -moz-background-size: auto;
  -o-background-size: auto;
  background-size: auto;
  position: absolute;
  width: 107%;
  height: 100%;
  animation: animate-bg-move 10s linear 0s infinite alternate;
  z-index: -1;
  margin-top: -60px;
}

.login-box-body .form-control-feedback {
  color: white;
  opacity: .5;
}

@keyframes animate-bg-color{
  0% {
    background-color: #000;
  }
}

@keyframes animate-bg-move{
  0% {
    transform: translateX(-1%) translateY(-1%);
  }
  25% {
    transform: translateX(0) translateY(-2%);
  }
  50% {
    transform: translateX(1%) translateY(-1%);
  }
  75% {
    transform: translateX(0%)  translateX(0%);
  }
  100% {
    transform: translateX(0%) translateX(-1%);
  }
}

.login-page .login-box{
  max-width: 380px;
  border: solid #7F7F7F;
  background: white;
  /*background: #00529c;*/
  padding: 2em;
  margin: 3em auto 2em auto;
}


.login-box h1, .login-box h2, .login-box h3, .login-box h4,  .login-box p{
  color: black;
}

.login-page .apptitle{
  text-align: center;
  margin-top: 2em;
}

.login-box-body, .register-box-body {
  background: none;
}

.has-feedback .form-control-feedback {
  top:0;
}

.login-logo{
  color: #ffffff;
  margin-bottom: 0;
}

.material-form {
  background: #2d507d;
}

/*FORMULARIO PARA INICIAR SESIÓN*/
.material-form input, .material-form .select-material button {
  background: none;
  border: none;
  border-bottom: 2px solid #005c9c;
  color: black;
  font-weight: bold;
  cursor: pointer;
  -webkit-font-smoothing: none;
  /*opacity: .5;*/
  /*filter: alpha(opacity=50);*/
  /*-moz-opacity:0.5;*/
}

.material-form input, .material-form select, .material-form button,.material-form textarea, .select-material button {
  letter-spacing: .05em;
  font-weight: lighter;
  font-size: 1em;
  width: 100%;
}

.material-form input[type=text]:hover, .material-form input[type=password]:hover, .material-form .select-material button:hover {
  background: none!important;
  border-bottom:2px solid #005c9c;
  /*border-bottom: 1px solid white;*/
  box-shadow: 0 1px 0 0 white;
  opacity: 1;
  filter: alpha(opacity=100);
  -moz-opacity:1;
}


.material-form .select-material button {
  text-align: left;
}

.material-form .select-material button .caret{
  position: relative;
  float: right;
  top: .5em;
}

.login-box .btn-default:active, .login-box .btn-default.active, .login-box .open > .dropdown-toggle.btn-default {
  background: none!important;
  border-bottom: 1px solid white;
  box-shadow: 0 1px 0 0 white;
  color: white!important;
}

.material-form .select-material button:active:hover, .material-form .select-material button.active:hover, .open > .dropdown-toggle.material-form .select-material button:hover,
.material-form .select-material button:active:focus, .material-form .select-material button.active:focus, .open > .dropdown-toggle.material-form .select-material button:focus,
.material-form .select-material button:active.focus, .material-form .select-material button.active.focus, .open > .dropdown-toggle.material-form .select-material button.focus {
  background: none!important;
  border-bottom: 1px solid white;
  box-shadow: 0 1px 0 0 white;
  color: white!important;
}

.material-form input:focus, .material-form select:focus {
  border-bottom: 2px solid #005c9c;
  box-shadow: 0 1px 0 0 white;
  opacity: 1;
  filter: alpha(opacity=100);
  -moz-opacity:1;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
}

.material-form .select-material button {
  background: none;
  border-radius:0;
}

.material-form .btn-primary {
  background: #005c9c;
  text-transform: capitalize;
  padding: .7em;
  color: white!important;
}

.material-form ::-webkit-input-placeholder {
  color: #7f7f7f;
}

.material-form :-moz-placeholder { /* Firefox 18- */
  color: black;
}

.material-form ::-moz-placeholder {  /* Firefox 19+ */
  color: black;
}

.material-form :-ms-input-placeholder {
  color: black;
}

.material-form .error-message {
  min-height: 4em;
  color: #ff8d98;
  padding: 1em;
  text-align: center;
}

.material-form select option {
  max-width: 90%;
  background: black;
  color: #333333;
  cursor: pointer;
}

.login-page .watermark {
  text-align: center;
  padding-right: 2em;
  /*font-weight: bold;*/
  /*color: white;*/
}

.login-page .watermark a{
  color: #3588c5;
}


/* CSS PARA BOTON DE LOGIN */

button.loadingButton.default-state > .circle, button.loadingButton.error-state > .circle {
  display: none;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

button.loadingButton.loading-state > .circle {
  display: block;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

button.loadingButton.loading-state > .default-text {
  display: none;
}

button.loadingButton.success-state {
  background: #2cb76b;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

button.loadingButton.success-state > .default-text {
  display: none;
}

button.loadingButton.error-state {
  background: #fc4128;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

button.loadingButton.error-state:hover {
  background: #fc4128;
}

button.loadingButton.success-state:hover {
  background: #2cb76b;
}

.circle {
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  margin: -.3em auto -.3em auto;
  display: block;
  background: none;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  overflow: hidden;
  border: .25em solid #FFF;
  border-bottom: .25em solid transparent;

}

.ani-spin {
  -webkit-animation: spin 1.2s linear 0s infinite normal;
  -moz-animation: spin 1.2s linear 0s infinite normal;
  -o-animation: spin 1.2s linear 0s infinite normal;
  animation: spin 1.2s linear 0s infinite normal;
}

@-webkit-keyframes rolling {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rolling {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moztransform: rotate(360deg);
  }
}
@-o-keyframes rolling {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes rolling {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}







/*

.login-page {

  background:url('/images/xpectra-wallpaper.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  background-position: right 0px center;

}

.login-box{

  width: 450px;

}

.has-feedback .form-control-feedback {
  top:0px;
}

.login-logo {

  color: #ffffff;
}
*/
