.loading-screen {
  position: relative;
  z-index: 99998;
  min-width: 300px;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  background: white;
  display: block;
  text-align: center;
}

.loading-screen .loading-text {
  text-transform: uppercase;
  color: #9a9a9a;
}

.loading-animation {
  padding: 0;
  margin: 2em auto .5em auto;
}

.loading-animation circle {
  margin: 1em auto .3em auto;
  fill: none;
  /*stroke: #355989;*/
  stroke: #9a9a9a;
  stroke-linecap: round;
  stroke-width: 7;
  -webkit-animation: circley 8s ease infinite, rotaty 2.2s linear infinite;
  -moz-animation: circley 8s ease infinite, rotaty 2.2s linear infinite;
  transform-origin: center center;
  text-align: center
}

.loading-animation circle.colored {
  -webkit-animation: circley 8s ease infinite, rotaty 2.2s linear infinite, colored 5.3333s ease infinite;
  -moz-animation: circley 8s ease infinite, rotaty 2.2s linear infinite, colored 5.3333s ease infinite;
}


.loading-animation circle.colored.slow-as-fuck {
  -webkit-animation: circley 216s ease infinite, rotaty 59.4s linear infinite, colored 144s ease infinite;
  -moz-animation: circley 216s ease infinite, rotaty 59.4s linear infinite, colored 144s ease infinite;
}

.loading-animation circle.white-loading{
  stroke: white !important;
}

@keyframes circley {
  0% {
    stroke-dashoffset: 0;
    stroke-dasharray: 0 314.159;
  }

  8.3% {
    stroke-dashoffset: 0;
    stroke-dasharray: 261.79 52.35;
  }

  16.6% {
    stroke-dashoffset: -261.79;
    stroke-dasharray: 0 314.159;
  }

  25% {
    stroke-dashoffset: -261.79;
    stroke-dasharray: 261.79 52.35;
  }

  33.3% {
    stroke-dashoffset: -523.58;
    stroke-dasharray: 0 314.159;
  }

  41.6% {
    stroke-dashoffset: -523.58;
    stroke-dasharray: 261.79 52.35;
  }

  50% {
    stroke-dashoffset: -785.37;
    stroke-dasharray: 0 314.159;
  }

  58.3% {
    stroke-dashoffset: -785.37;
    stroke-dasharray: 261.79 52.35;
  }

  66.6% {
    stroke-dashoffset: -1047.16;
    stroke-dasharray: 0 314.159;
  }

  75% {
    stroke-dashoffset: -1047.16;
    stroke-dasharray: 261.79 52.35;
  }

  83.3% {
    stroke-dashoffset: -1308.95;
    stroke-dasharray: 0 314.159;
  }

  91.6% {
    stroke-dashoffset: -1308.95;
    stroke-dasharray: 261.79 52.35;
  }

  100% {
    stroke-dashoffset: -1570.74;
    stroke-dasharray: 0 314.159;
  }
}

@-webkit-keyframes circley {
  0% {
    stroke-dashoffset: 0;
    stroke-dasharray: 0 314.159;
  }

  8.3% {
    stroke-dashoffset: 0;
    stroke-dasharray: 261.79 52.35;
  }

  16.6% {
    stroke-dashoffset: -261.79;
    stroke-dasharray: 0 314.159;
  }

  25% {
    stroke-dashoffset: -261.79;
    stroke-dasharray: 261.79 52.35;
  }

  33.3% {
    stroke-dashoffset: -523.58;
    stroke-dasharray: 0 314.159;
  }

  41.6% {
    stroke-dashoffset: -523.58;
    stroke-dasharray: 261.79 52.35;
  }

  50% {
    stroke-dashoffset: -785.37;
    stroke-dasharray: 0 314.159;
  }

  58.3% {
    stroke-dashoffset: -785.37;
    stroke-dasharray: 261.79 52.35;
  }

  66.6% {
    stroke-dashoffset: -1047.16;
    stroke-dasharray: 0 314.159;
  }

  75% {
    stroke-dashoffset: -1047.16;
    stroke-dasharray: 261.79 52.35;
  }

  83.3% {
    stroke-dashoffset: -1308.95;
    stroke-dasharray: 0 314.159;
  }

  91.6% {
    stroke-dashoffset: -1308.95;
    stroke-dasharray: 261.79 52.35;
  }

  100% {
    stroke-dashoffset: -1570.74;
    stroke-dasharray: 0 314.159;
  }
}

@keyframes rotaty {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotaty {
  0% {
    transform: rotate(0deg);

  }

  100% {
    transform: rotate(360deg);

  }
}
