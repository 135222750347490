

.supermini {
  padding: 0 2px;
  margin-left: 2px;
  margin-right: 2px;
}

.btn-pickcolor, .btn-pickcolor:hover, .btn-pickcolor:focus {
  color: grey;
  outline: none!important;
  border: 1px solid #d2d6de;
  width: 34px;
  height: 34px;
}

.has-success .btn-pickcolor {
  border-color: #00a65a !important;
  box-shadow: none;
}

.has-error .btn-pickcolor {
  border-color: #dd4b39 !important;
  box-shadow: none;
}

.icon-without-icon:before {
  display: none;
  /*content: " ";
  width: 12px;
  height: 20px;
  float: left;*/
}

.icon-without-icon {
}

.btn.is-loading[disabled] {
  opacity: 1;
}

.btn {
  position: relative;
  overflow: hidden;

  /*  -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;*/
}

.btn-add{
  background-color: #ddd !important;
  color: #555;
  margin-top: 3px;
  margin-right: 10px;
}

.btn-tag{
  margin-top: 3px;
  margin-right: 10px;
}

a.btn.btn-help {
  padding: 0;
  margin-top: -4px;
}

.is-loading > span.progress .progress-inner {
  display: block;
  animation: isloadingprogress 2s linear .2s infinite;
}

span.progress .progress-inner {
  position: absolute;
  bottom: 0;
  height: 3px;
  width: 40%;
  background: rgba(0,0,0,.2);
  left: 0;
  display: none;
}

@keyframes isloadingprogress {
  0% {
    left: 0;
  }
  100% {
    width: 10%;
    left: 100%;
  }
}


fieldset[disabled] a.btn.btn-help, fieldset[disabled] button.btn.btn-help {
  pointer-events: auto;
  cursor: pointer;
  opacity: 1;
  padding: 0;
  margin-top: -4px;
}
