@charset "UTF-8";

@font-face {
  font-family: "font-xpectra-icons";
  src:url("fonts/font-xpectra-icons.eot");
  src:url("fonts/font-xpectra-icons.eot?#iefix") format("embedded-opentype"),
    url("fonts/font-xpectra-icons.woff") format("woff"),
    url("fonts/font-xpectra-icons.ttf") format("truetype"),
    url("fonts/font-xpectra-icons.svg#font-xpectra-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "font-xpectra-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "font-xpectra-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-ico-ed-alarma:before {
  content: "\61";
}
.icon-ico-ed-sensor-calor:before {
  content: "\62";
}
.icon-ico-ed-sensor-congelamiento:before {
  content: "\63";
}
.icon-ico-ed-boton-panico:before {
  content: "\64";
}
.icon-ico-ed-camara:before {
  content: "\65";
}
.icon-ico-ed-sensor-fuego:before {
  content: "\66";
}
.icon-ico-ed-sensor-gas:before {
  content: "\67";
}
.icon-ico-ed-camara-ip:before {
  content: "\68";
}
.icon-ico-ed-camara-ip-wifi:before {
  content: "\69";
}
.icon-ico-ed-sensor-inundacion:before {
  content: "\6b";
}
.icon-ico-ed-camara-ptz:before {
  content: "\6c";
}
.icon-ico-ed-camara-ptz-ip:before {
  content: "\6d";
}
.icon-ico-ed-sensor-presencia:before {
  content: "\6e";
}
.icon-ico-ed-sensor-rotura:before {
  content: "\6a";
}
.icon-ico-ed-camara-ptz-ip-wifi:before {
  content: "\6f";
}
.icon-ico-ed-dvr:before {
  content: "\70";
}
.icon-ico-ed-sirena:before {
  content: "\71";
}
.icon-ico-ed-teclado:before {
  content: "\72";
}
.icon-ico-ed-nvr:before {
  content: "\73";
}
.icon-ico-ed-panel:before {
  content: "\74";
}
.icon-ico-ed-rociador-agua:before {
  content: "\75";
}
.icon-ico-ed-sensor-apertura:before {
  content: "\76";
}
.icon-ico-ea-alarma:before {
  content: "\77";
}
.icon-ico-ea-armado-auto-falla:before {
  content: "\78";
}
.icon-ico-ea-calor-zc:before {
  content: "\79";
}
.icon-ico-ea-congelamiento:before {
  content: "\7a";
}
.icon-ico-ea-falla-baterias:before {
  content: "\41";
}
.icon-ico-ea-falla-comunicacion:before {
  content: "\42";
}
.icon-ico-ea-congelamiento-zc:before {
  content: "\43";
}
.icon-ico-ea-cambio-programacion:before {
  content: "\44";
}
.icon-ico-ea-armado-automatico:before {
  content: "\45";
}
.icon-ico-ea-alarma-zc:before {
  content: "\46";
}
.icon-ico-ea-apertura:before {
  content: "\47";
}
.icon-ico-ea-armado-falla:before {
  content: "\48";
}
.icon-ico-ea-cierre:before {
  content: "\49";
}
.icon-ico-ea-desarmado:before {
  content: "\4c";
}
.icon-ico-ea-falla-netbox:before {
  content: "\4d";
}
.icon-ico-ea-falla-potencia:before {
  content: "\4e";
}
.icon-ico-ea-armado-fuera-horario:before {
  content: "\4f";
}
.icon-ico-ea-armado-parcial:before {
  content: "\50";
}
.icon-ico-ea-cierre-automatico:before {
  content: "\51";
}
.icon-ico-ea-desarmado-automatico:before {
  content: "\4a";
}
.icon-ico-ea-desarmado-falla:before {
  content: "\4b";
}
.icon-ico-ea-cierre-falla:before {
  content: "\52";
}
.icon-ico-ea-armado-remoto:before {
  content: "\53";
}
.icon-ico-ea-apertura-falla:before {
  content: "\54";
}
.icon-ico-ea-apertura-fuera-horario:before {
  content: "\55";
}
.icon-ico-ea-armado-tardio:before {
  content: "\56";
}
.icon-ico-ea-cierre-fuera-horario:before {
  content: "\57";
}
.icon-ico-ea-desarmado-fuera-horario:before {
  content: "\58";
}
.icon-ico-ea-fuego:before {
  content: "\59";
}
.icon-ico-ea-falla-zona:before {
  content: "\5a";
}
.icon-ico-ea-falla-receptora:before {
  content: "\30";
}
.icon-ico-ea-intrusion-zc:before {
  content: "\31";
}
.icon-ico-ea-inundacion:before {
  content: "\32";
}
.icon-ico-ea-inundacion-zc:before {
  content: "\33";
}
.icon-ico-ea-medico:before {
  content: "\34";
}
.icon-ico-ea-medico-zc:before {
  content: "\35";
}
.icon-ico-ea-panico:before {
  content: "\36";
}
.icon-ico-ea-panico-fuego:before {
  content: "\37";
}
.icon-ico-ea-panico-fuego-zc:before {
  content: "\38";
}
.icon-ico-ea-fuego-zc:before {
  content: "\39";
}
.icon-ico-ea-desarmado-remoto:before {
  content: "\21";
}
.icon-ico-ea-cierre-parcial:before {
  content: "\22";
}
.icon-ico-ea-armado-temprano:before {
  content: "\23";
}
.icon-ico-ea-apertura-remoto:before {
  content: "\24";
}
.icon-ico-ea-apertura-tarde:before {
  content: "\25";
}
.icon-ico-ea-asistencia:before {
  content: "\26";
}
.icon-ico-ea-cierre-remoto:before {
  content: "\27";
}
.icon-ico-ea-desarmado-tardio:before {
  content: "\28";
}
.icon-ico-ea-gas:before {
  content: "\29";
}
.icon-ico-ea-panico-inundacion:before {
  content: "\2a";
}
.icon-ico-ea-panico-inundacion-zc:before {
  content: "\2b";
}
.icon-ico-ea-gas-zc:before {
  content: "\2c";
}
.icon-ico-ea-desarmado-temprano:before {
  content: "\2d";
}
.icon-ico-ea-cierre-tarde:before {
  content: "\2e";
}
.icon-ico-ea-asistencia-zc:before {
  content: "\2f";
}
.icon-ico-ea-apertura-temprano:before {
  content: "\3a";
}
.icon-ico-ea-aperturas-cierres:before {
  content: "\3b";
}
.icon-ico-ea-bypass:before {
  content: "\3c";
}
.icon-ico-ea-emergencia:before {
  content: "\3d";
}
.icon-ico-ea-cierre-temprano:before {
  content: "\3e";
}
.icon-ico-ea-co2:before {
  content: "\3f";
}
.icon-ico-ea-bypass-normalizacion:before {
  content: "\40";
}
.icon-ico-ea-armado:before {
  content: "\5b";
}
.icon-ico-ea-calor:before {
  content: "\5e";
}
.icon-ico-ea-co2-zc:before {
  content: "\5f";
}
.icon-ico-ea-falla:before {
  content: "\60";
}
.icon-ico-ea-emergencia-zc:before {
  content: "\5d";
}
.icon-ico-ea-intrusion:before {
  content: "\7b";
}
.icon-ico-ea-panico-movil:before {
  content: "\7c";
}
.icon-ico-ea-panico-medico-zc:before {
  content: "\7d";
}
.icon-ico-ea-panico-medico:before {
  content: "\7e";
}
.icon-ico-ea-panico-robo-zc:before {
  content: "\5c";
}
.icon-ico-ea-panico-zc:before {
  content: "\e000";
}
.icon-ico-ea-reporte:before {
  content: "\e001";
}
.icon-ico-ea-tamper:before {
  content: "\e002";
}
.icon-ico-ea-supervisor:before {
  content: "\e003";
}
.icon-ico-ea-sometido-zc:before {
  content: "\e004";
}
.icon-ico-ea-sometido:before {
  content: "\e005";
}
.icon-ico-ea-senal-test:before {
  content: "\e006";
}
.icon-ico-ea-senal-no-configurada:before {
  content: "\e007";
}
.icon-ico-ea-senal-desconocida:before {
  content: "\e008";
}
.icon-ico-ea-rociador-zc:before {
  content: "\e009";
}
.icon-ico-ea-rociador:before {
  content: "\e00a";
}
.icon-ico-ea-robo-zc:before {
  content: "\e00b";
}
.icon-ico-ea-robo:before {
  content: "\e00c";
}
.icon-ico-ea-reporte-cona:before {
  content: "\e00d";
}
.icon-ico-ea-panico-movil-alt:before {
  content: "\e00e";
}
.icon-ico-ea-panico-movil-asistencia:before {
  content: "\e00f";
}
.icon-ico-ea-panico-movil-asistencia-alt:before {
  content: "\e010";
}
.icon-ico-ea-panico-movil-fuego-alt:before {
  content: "\e011";
}
.icon-ico-ea-panico-movil-medico:before {
  content: "\e012";
}
.icon-ico-ea-panico-movil-medico-alt:before {
  content: "\e013";
}
.icon-ico-ea-panico-movil-robo:before {
  content: "\e014";
}
.icon-ico-ea-panico-movil-robo-alt:before {
  content: "\e015";
}
.icon-ico-ea-panico-robo:before {
  content: "\e016";
}
.icon-ico-ea-panico-movil-fuego:before {
  content: "\e017";
}
.icon-ico-cloud-actualizar:before {
  content: "\e018";
}
.icon-ico-cloud-facturacion-pagos:before {
  content: "\e019";
}
.icon-ico-cloud-solicitud:before {
  content: "\e01a";
}
.icon-ico-cloud-solicitud-cancelada:before {
  content: "\e01b";
}
.icon-ico-cloud-idioma:before {
  content: "\e01c";
}
.icon-ico-cloud-ajustes:before {
  content: "\e01d";
}
.icon-ico-cloud-alarmas-eventos:before {
  content: "\e01e";
}
.icon-ico-cloud-localizacion-en-mapa:before {
  content: "\e01f";
}
.icon-ico-cloud-soporte-t-ecnico-alt:before {
  content: "\e020";
}
.icon-ico-cloud-soporte-tecnico:before {
  content: "\e021";
}
.icon-ico-cloud-mapa:before {
  content: "\e022";
}
.icon-ico-cloud-borrar:before {
  content: "\e023";
}
.icon-ico-cloud-calendario:before {
  content: "\e024";
}
.icon-ico-cloud-notificaciones:before {
  content: "\e025";
}
.icon-ico-cloud-telefono:before {
  content: "\e026";
}
.icon-ico-cloud-ticket:before {
  content: "\e027";
}
.icon-ico-cloud-nueva-solicitud:before {
  content: "\e028";
}
.icon-ico-cloud-camara:before {
  content: "\e029";
}
.icon-ico-cloud-cliente:before {
  content: "\e02a";
}
.icon-ico-cloud-pagos:before {
  content: "\e02b";
}
.icon-ico-cloud-ticket-en-proceso:before {
  content: "\e02c";
}
.icon-ico-cloud-ticket-finalizado:before {
  content: "\e02d";
}
.icon-ico-cloud-panel-armado:before {
  content: "\e02e";
}
.icon-ico-cloud-configuracin-cuenta:before {
  content: "\e02f";
}
.icon-ico-cloud-cuenta:before {
  content: "\e030";
}
.icon-ico-cloud-panel-desarmado:before {
  content: "\e031";
}
.icon-ico-cloud-ticket-planificado:before {
  content: "\e032";
}
.icon-ico-cloud-panel-zonas-alarma:before {
  content: "\e033";
}
.icon-ico-cloud-cuenta-alt:before {
  content: "\e034";
}
.icon-ico-cloud-detalle-cuenta:before {
  content: "\e035";
}
.icon-ico-cloud-panel-zonas-bypass:before {
  content: "\e036";
}
.icon-ico-cloud-reportes:before {
  content: "\e037";
}
.icon-ico-cloud-email:before {
  content: "\e038";
}
.icon-ico-cloud-facturacion:before {
  content: "\e039";
}
.icon-ico-cloud-salir:before {
  content: "\e03a";
}
.icon-ico-cloud-apps-panico:before {
  content: "\e03b";
}
.icon-ico-cancelar:before {
  content: "\e042";
}
.icon-ico-pausar:before {
  content: "\e043";
}
.icon-aceptar:before {
  content: "\e03c";
}
.icon-atender:before {
  content: "\e03d";
}
.icon-detalle:before {
  content: "\e03e";
}
.icon-historial:before {
  content: "\e03f";
}
.icon-nota:before {
  content: "\e040";
}
.icon-pausar:before {
  content: "\e041";
}
.icon-rechazar:before {
  content: "\e044";
}
.icon-terminar:before {
  content: "\e045";
}
.icon-ico-ea-apertura-ausencia:before {
  content: "\e046";
}
.icon-ico-ea-armado-ausencia:before {
  content: "\e047";
}
.icon-ico-ea-cierre-ausencia:before {
  content: "\e048";
}
.icon-ico-ea-desarmado-ausencia:before {
  content: "\e049";
}
.icon-ico-ea-humo:before {
  content: "\e04a";
}
.icon-ico-ea-humo-zc:before {
  content: "\e04b";
}
.icon-ico-sec-ayuda:before {
  content: "\e04c";
}
