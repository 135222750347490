/* #### - corrects 'unfixing' bug in Safari - #### */
@media screen and (-webkit-min-device-pixel-ratio:0) { .containermenu { -webkit-transform:none !important } }

body { overflow-x:hidden; -webkit-animation:androidbugfix infinite 1s } /* http://timpietrusky.com/advanced-checkbox-hack */
@-webkit-keyframes androidbugfix { from {padding:0} to {padding:0} } /* http://timpietrusky.com/advanced-checkbox-hack */

[id$="-menu"] label
{ padding:0 0.25em;
  font-size: 2em;
}
[id$="-menu"] .sub-nav .toggle { padding:0 0.3125em; /*font:2em/2em Arial;*/ top:0; -webkit-transform:scale(-1,1); -ms-transform:scale(-1,1); transform:scale(-1,1) }
#header label:hover, [id$="-menu"] label:hover { color:#ccc }
[id$="-main-nav-check"], [id$="-menu"] .sub-nav-check { position:absolute; top:-9999px; left:-9999px }
[id$="-menu"] .toggle { position:absolute; right:0.15em; cursor:pointer; color:#fff }
.containermenu { background:#fff; min-height:100%; padding:0; margin:0 }

[id$="-menu"]
{
  margin-top: 3px;
  margin-bottom: 7px;
}

[id$="-menu"], [id$="-menu"] .sub-nav { position:absolute; top:0; bottom:0; width:13.75em; background:#3b3b3d; z-index:9999; left:-13.75em }
.containermenu, [id$="-menu"], [id$="-menu"] .sub-nav {
  -webkit-transform:translate(0,0); -ms-transform:translate(0,0); transform:translate(0,0);
  -webkit-transition:-webkit-transform .25s ease; transition:transform .25s ease
}
[id$="-menu"] ul { background:#3b3b3d; line-height:2.75em; text-decoration:none; padding:0; list-style:none;/* margin-top:4.0625em;*/ margin-top:3.3em; border-top:1px solid #444 }
[id$="-menu"] li { border-bottom:1px solid #444 }
[id$="-menu"] li a { background:#3b3b3d; display:block; /*font-size:0.875em;*/ padding-left:1.5em; color:#fff; text-decoration:none; text-transform: capitalize; cursor: pointer; }
[id$="-menu"] li a:hover, [id$="-menu"] .toggle-sub:hover { background:#333 }
[id$="-menu"] .sub-nav { margin-top:-1px }
[id$="-menu"] .sub-heading { /*margin-top:4.0625em;*/ margin-top:3.3em; padding-left:1.3125em; color:#00add8; text-transform: uppercase; }
[id$="-menu"] .sub-heading:before { content:""; display:block; height:1px; width:13.75em; margin-left:-1.3125em; background:#3b3b3d; }
[id$="-menu"] .toggle-sub { font:bold 1em/2.75em Arial; color:#fff; background:#3b3b3d; float:right; margin-top:-2.75em; width:2.25em; border-left:1px solid #444; cursor:pointer; text-align:center }
.containermenu, [id$="-menu"], [id$="-menu"] .sub-nav { -webkit-transform:translate3d(0,0,0) } /* http://davidwalsh.name/translate3d */

/* #### - corrects 'unfixing' bug in Safari - uncomment if you have position:fixed elements inside .containermenu - #### */
/* @media screen and (-webkit-min-device-pixel-ratio:0) { .containermenu { -webkit-transform:none !important } } */

/* #### - define ids of animated containermenu, menu and sub-menus here - #### */
[id$="-main-nav-check"]:checked ~ .containermenu,
[id$="-main-nav-check"]:checked ~ [id$="-menu"],
[id$="-menu"] .sub-nav-check:checked ~ [id$="-sub"] /* matches all elements with id ending "-sub" */ {
  -webkit-transform:translate(13.75em,0); -ms-transform:translate(13.75em,0); transform:translate(13.75em,0)
}

.widgetZone .box-primary, .widget-settings .box-primary {
   border: 1px solid #DDDDDD;
}

.widgetZone .box-primary .box-header,.widget-settings .box-primary .box-header {
  border-bottom: 1px solid #DDDDDD;
  background: #ededed;
  margin-bottom: .5em;
}

.widgetZone .box-primary .box-header h3, .widget-settings .box-primary .box-header h3 {
  font-size: 90%;
}

.widgetZone .box-primary .box-header .btn, .widget-settings .box-primary .box-header .btn  {
  font-size: 90%;
}


.indicator-filters-center {
  margin-bottom: 1em;
  padding-bottom: .5em;
  border-bottom: 1px solid #DDDDDD;
}

.indicator-filters-center  .pull-left {
  margin-left: 1em;
}

.widgetZone .drag {
  position: absolute;
  z-index: 1;
  width: 100%;
  cursor: move;
  cursor: -webkit-grab;
  height: 15px;
  display: none;
}
.widgetZone .drag:hover {
  background: #CCC;
  opacity: 0.4;
  filter: alpha(opacity=40);
}

.widgetZone .drag:active {
  cursor: -webkit-grabbing;
}

.widgetZone .bg-black {
  background-color: #3e3e3e!important;
}

.widgetZone .bg-green {
  background-color: #2cb76b!important;
}

.widgetZone .bg-red {
  background-color: #fc4128!important;
}

.widgetZone .bg-orange {
  background-color: #e1ae5b!important;
}

.widgetZone .bg-blue {
  background-color: #355989!important;
}

.widgetZone .bg-yellow {
  background-color: #ffc400!important;
}

.widgetZone .empty-widget a{
  border: 4px dashed #CCCCCC;
  border-radius: 5px;
  padding: 2.5em;
  font-size: 2.5em;
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
  color: #CCCCCC;
  text-align: center;
}

.widgetZone .empty-widget a:hover {
  border-color: #72afd2;
  color: #72afd2;
}

.widgetZone .widget {
  margin-top: 10px;
  margin-bottom:  10px;
}

.widgetZone .widget-4 a {
  padding: 1em!important;
}

.widgetZone .small-box .icon {
  font-size: 35px;
  top: -5px;
  right: 5px;
}

.widgetZone .small-box:hover .icon {
  font-size: 40px;
}

.widgetZone .widgetEscalarItem {
  padding-right: 5px;
  padding-left: 5px;
  animation: loaded-animation 1.4s;
  /* min-width: 150px;*/
}

.widgetZone .highcharts-container {
  animation: loaded-animation 1.4s ease-in-out;
}

@keyframes loaded-animation{
  from {
    top: 50px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.widgetZone .widgetEscalarItem h3{
  text-align: center;
}

.widgetZone .widgetEscalarItem .title{
  margin: 0;
  height: 50px;
  overflow: hidden;
}

.widgetZone .widgetEscalarItem .title{
  margin: 0;
  /*height: 62px;*/
  overflow: hidden;
}

.widgetZone .widgetEscalarItem .small-box-footer {
  bottom: 0;
  position: absolute;
  width: 100%;
}


.widgetZone .widgetChartItem{
  padding-right: 5px;
  padding-left: 5px;
}

.widgetZone .small-box, .widgetZone .box {
  margin-bottom: 10px;
}

.widgetZone .highcharts-button {
  display: none;
}

.highcharts-container {
  width: 100%!important;
}

.highcharts-container svg{
  width: 100%!important;
}

.highcharts-background {
  width: 100%!important;
}


.chart-wrapper {
  position: relative;
  padding-bottom: 40%;
}

.chart-inner {
  position: absolute;
  width: 100%; height: 100%;
}

.highcharts-grid {
  width: 100%!important;
}

.widgetZone .minichart .box-title {
  font-size: 0.8em;
  white-space:pre;
}

.widgetZone .minichart .box-title span{
  clear: left;
  font-size: 3em;
  font-weight: 600;
}

.widgetZone .dropdown-menu, .widgetZone .dropdown-submenu{
  background: #3b3b3d;
  color: #FFF;
  border-color: #545454;
}
.widgetZone .dropdown-menu > .divider {
  background-color: #5c5c5c;
}

.widgetZone .dropdown-menu > li > a {
  color: #b8c7ce;
}

.widgetZone .dropdown-menu > li > a:hover {
  color: #1e282c;
}

.widgetZone .dropdown-header, .widgetZone .dropdown-menu > li > a.dropdown-subheader {
  color: #00add8;
  text-transform: uppercase;
  font-size: .85em;
}

.widgetZone .dropdown-submenu>a.dropdown-subheader:after {
  border-left-color: #00add8;
}

.widgetZone .overFade:hover > .box-header .overFadeItem {
  opacity: 1;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.widgetZone .box-header .overFadeItem {
  /*.indicators .box-header i.fa {*/
  opacity: 0.2;
}

.widgetZone .charts-container {
  position: relative;
}

/*
.widgetZone .datapicker-indicator-container {
  float: left;
  width: 100%;
  height: 100%;
  background-color: #3b3b3d;
}

.widgetZone .datapicker-indicator-container .datepicker-indicator {
  -ms-transform: scale(.91,.91);  IE 9
  -webkit-transform: scale(.91,.91);  Safari
  transform: scale(.91,.91);
}*/

/*Just add this css for responsive gridster */
.dashboard .gridster > * {
  padding: 0;
  width: 100% !important;
}
.dashboard .gridster .gs-w {
  list-style: none;
}
.dashboard .gridster .gs-w[data-sizex="1"] {
  width: 8.34091%;
}
.dashboard .gridster .gs-w[data-col="1"] {
  left: 0;
}
.dashboard .gridster .gs-w[data-sizex="2"] {
  width: 17.43182%;
}
.dashboard .gridster .gs-w[data-col="2"] {
  left: 9.09091%;
}
.dashboard.gridster .gs-w[data-sizex="3"] {
  width: 26.52273%;
}
.dashboard .gridster .gs-w[data-col="3"] {
  left: 18.18182%;
}
.dashboard .gridster .gs-w[data-sizex="4"] {
  width: 35.61364%;
}
.dashboard .gridster .gs-w[data-col="4"] {
  left: 27.27273%;
}
.dashboard .gridster .gs-w[data-sizex="5"] {
  width: 44.70455%;
}
.dashboard.gridster .gs-w[data-col="5"] {
  left: 36.36364%;
}
.dashboard .gridster .gs-w[data-sizex="6"] {
  width: 53.79545%;
}
.dashboard.gridster .gs-w[data-col="6"] {
  left: 45.45455%;
}
.dashboard .gridster .gs-w[data-sizex="7"] {
  width: 62.88636%;
}
.dashboard.gridster .gs-w[data-col="7"] {
  left: 54.54545%;
}
.dashboard .gridster .gs-w[data-sizex="8"] {
  width: 71.97727%;
}
.dashboard .gridster .gs-w[data-col="8"] {
  left: 63.63636%;
}
.dashboard .gridster .gs-w[data-sizex="9"] {
  width: 81.06818%;
}
.dashboard .gridster .gs-w[data-col="9"] {
  left: 72.72727%;
}
.dashboard .gridster .gs-w[data-sizex="10"] {
  width: 90.15909%;
}
.dashboard .gridster .gs-w[data-col="10"] {
  left: 81.81818%;
}
.dashboard .gridster .gs-w[data-sizex="11"] {
  width: 99.25%;
}
.dashboard .gridster .gs-w[data-col="11"] {
  left: 90.90909%;
}

/*Work around for devices less than 768px*/
@media screen and (max-width: 1024px) {
  .dashboard .gridster > * {
    padding: 0;
    width: 100% !important;
  }
  .dashboard .gridster .gs-w {
    list-style: none;
  }
  .dashboard .gridster .gs-w[data-sizex="1"] {
    width: 100%!important;
  }
  .dashboard .gridster .gs-w[data-col="1"] {
    left: 0!important;
  }
  .dashboard .gridster .gs-w[data-sizex="2"] {
    width: 100%!important;
  }
  .dashboard .gridster .gs-w[data-col="2"] {
    left: 0!important;
  }
  .dashboard .gridster .gs-w[data-sizex="3"] {
    width: 100%!important;
  }
  .dashboard .gridster .gs-w[data-col="3"] {
    left: 0!important;
  }
  .dashboard .gridster .gs-w[data-sizex="4"] {
    width: 100%!important;
  }
  .dashboard .gridster .gs-w[data-col="4"] {
    left: 0!important;
  }
  .dashboard .gridster .gs-w[data-sizex="5"] {
    width: 100%!important;
  }
  .dashboard .gridster .gs-w[data-col="5"] {
    left: 0!important;
  }
  .dashboard .gridster .gs-w[data-sizex="6"] {
    width: 100%!important;
  }
  .dashboard .gridster .gs-w[data-col="6"] {
    left: 0!important;
  }
  .dashboard .gridster .gs-w[data-sizex="7"] {
    width: 100%!important;
  }
  .dashboard .gridster .gs-w[data-col="7"] {
    left: 0!important;
  }
  .dashboard .gridster .gs-w[data-sizex="8"] {
    width: 100%!important;
  }
  .dashboard .gridster .gs-w[data-col="8"] {
    left: 0!important;
  }
  .dashboard .gridster .gs-w[data-sizex="9"] {
    width: 100%!important;
  }
  .dashboard .gridster .gs-w[data-col="9"] {
    left: 0!important;
  }
  .dashboard .gridster .gs-w[data-sizex="10"] {
    width: 100%!important;
  }
  .dashboard .gridster .gs-w[data-col="10"] {
    left: 0!important;
  }
  .dashboard .gridster .gs-w[data-sizex="11"] {
    width: 100%!important;
  }
  .dashboard .gridster .gs-w[data-col="11"] {
    left: 0!important;
  }
}

@media (min-width: 768px) {
  .accumulator-totals {
    border: 2px solid #dddddd;
    margin:.5em 1em;
    padding: 1.5em;
    border-radius: .5em;
  }
  .accumulator-totals h4{
    border-bottom: 1px solid #dddddd;
    margin: 0 0 .3em 0;
    padding: 0 0 .3em 0;
  }
  .accumulator-totals .accumulator-totals-item{
    border-bottom: 1px solid #dddddd;
    padding: .7em;
    text-align: right;
  }
  .accumulator-totals .accumulator-totals-item span{
    font-weight: bold;
    float: left;
    color: #6a6c6f;
  }
}
