.message-view {
  text-align: center;
}

.message-view img {
  max-width: 250px;
  width: 100%;
  margin: .3em auto;
  clear: both;
  opacity: .3;
}

.message-view span {
  clear: both;
  padding: .5em;
  font-size: 130%;
  opacity: .5;
}
