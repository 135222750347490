.ui-tabs-scrollable {
  position: relative; }
  .ui-tabs-scrollable > .spacer:not(.hidden-buttons) {
    margin-left: 0;
    margin-right: calc(20px + 20px); }
    .ui-tabs-scrollable > .spacer:not(.hidden-buttons) > div:first-child > .tab-content {
      margin-left: 0px;
      margin-right: calc(-20px - 20px); }
  .ui-tabs-scrollable > .spacer.hidden-buttons > div:first-child > .nav-tabs > li:last-child > a {
    border-right-color: #d9d9d9; }
  .ui-tabs-scrollable > .spacer.hidden-buttons > div:first-child > .nav-tabs > li:last-child.active > a {
    border-right-color: #999; }
  .ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs {
    white-space: nowrap;
    overflow: hidden;
    border: none; }
    .ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li {
      float: none;
      display: table-cell;
      margin: 0; }
      .ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li.active > a {
        border-radius: 3px 3px 0 0;
        border-color: #999;
        border-bottom-color: transparent;
        background-color: #FBFBFB;
        color: #000; }
      .ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li.active + li > a {
        border-left-color: #F2F2F2; }
      .ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li.active + li > a:hover {
        border-left-color: #e5e5e5; }
      .ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li:not(.active) > a {
        border-bottom-color: #999; }
        .ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li:not(.active) > a:hover {
          background-color: #e5e5e5; }
      .ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li.disabled:not(.active) > a {
        color: #9d9d9d; }
        .ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li.disabled:not(.active) > a:hover {
          background-color: #F2F2F2; }
      .ui-tabs-scrollable > .spacer > div:first-child > .nav-tabs > li > a {
        line-height: 35px;
        height: 35px;
        font-size: 12px;
        overflow: hidden;
        background-color: #F2F2F2;
        border: 1px solid #d9d9d9;
        border-right-color: transparent;
        border-radius: 0;
        padding: 0 15px;
        margin: 0;
        color: #666666; }
  .ui-tabs-scrollable > .spacer > div:first-child > .tab-content {
    margin-top: -1px;
    border-top: 1px solid #999; }
  .ui-tabs-scrollable > .nav-button {
    position: absolute;
    width: 20px;
    line-height: 35px;
    height: 35px;
    top: 0;
    cursor: pointer;
    text-align: center;
    border: 1px solid #d9d9d9;
    border-bottom-color: #999;
    border-radius: 0;
    padding: 0;
    background-color: #fff;
    z-index: 10;
    box-shadow: none; }
    .ui-tabs-scrollable > .nav-button:hover, .ui-tabs-scrollable > .nav-button:active {
      background-color: #e5e5e5; }
    .ui-tabs-scrollable > .nav-button:before {
      font-family: 'Glyphicons Halflings';
      color: #8c8c8c;
      font-size: 10px; }
    .ui-tabs-scrollable > .nav-button:disabled:before {
      color: #c5c5c5; }
    .ui-tabs-scrollable > .nav-button.left-nav-button {
      right: 20px;
      border-right: 0; }
      .ui-tabs-scrollable > .nav-button.left-nav-button:before {
        content: '\e079'; }
    .ui-tabs-scrollable > .nav-button.right-nav-button {
      right: 0; }
      .ui-tabs-scrollable > .nav-button.right-nav-button:before {
        content: '\e080'; }
  .ui-tabs-scrollable > .dropdown {
    position: absolute;
    top: 0;
    right: 0; }
    .ui-tabs-scrollable > .dropdown > .dropdown-toggle {
      width: 20px;
      line-height: 35px;
      height: 35px;
      font-size: 10px;
      color: #8c8c8c;
      text-align: center;
      border: 1px solid #ddd;
      border-left-width: 0;
      padding: 0;
      background-color: #fff;
      z-index: 10;
      border-radius: 0;
      border-bottom-color: #999; }
      .ui-tabs-scrollable > .dropdown > .dropdown-toggle:before {
        font-family: 'Glyphicons Halflings';
        content: '\e114';
        font-size: 10px; }
  .ui-tabs-scrollable.show-drop-down > .spacer:not(.hidden-buttons) {
    margin-right: calc(20px + 20px + 20px); }
    .ui-tabs-scrollable.show-drop-down > .spacer:not(.hidden-buttons) > div:first-child > .tab-content {
      margin-right: calc(-20px - 20px - 20px); }
  .ui-tabs-scrollable.show-drop-down > .nav-button.right-nav-button {
    right: 20px; }
  .ui-tabs-scrollable.show-drop-down > .nav-button.left-nav-button {
    right: calc(20px + 20px); }

.dropdown-menu {
  min-width: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); }
  .dropdown-menu > li > a {
    padding: 3px 15px; }
    .dropdown-menu > li > a > .dropDownTabActiveMark:before {
      font-family: 'Glyphicons Halflings';
      content: '\e072';
      padding-right: 7px;
      font-size: 10px;
      color: #777; }
  .dropdown-menu > li.active > a {
    color: inherit;
    background-color: inherit; }
    .dropdown-menu > li.active > a:hover {
      color: inherit;
      background-color: #f5f5f5; }

/*make the tabs content be height 100%*/
.ui-tabs-scrollable > .spacer {
  height: 100%; }
  .ui-tabs-scrollable > .spacer > div:first-child {
    height: 100%; }
    .ui-tabs-scrollable > .spacer > div:first-child > .tab-content {
      height: 100%; }
      .ui-tabs-scrollable > .spacer > div:first-child > .tab-content > .active {
        height: 100%;
        overflow: auto; }
