div.pages{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    z-index: 99999999;
    background-color: white;
}

div.pages img{
    max-width: 50%;
    padding-left: 10%;
    padding-right: 10%;
    position: relative;
    margin: auto;
    left: 0;
    top:50%;
    margin-top:50px;
    right: 0;
}


div.pages h1{
    font-weight:bolder;
    color:#000000;
    margin-top: 50px;
    margin-bottom: 50px;
}

div.pages button {
    position: relative;

    display: block;
    margin: 30px auto;
    padding: 0;

    overflow: hidden;

    border-width: 0;
    outline: none;
    border-radius: 2px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .6);

    background-color: #ff1744;
    color: #ecf0f1;

    transition: background-color .3s;
}

div.pages button:hover, div.pages button:focus {
    background-color: #ff616f;
}

div.pages button > * {
    position: relative;
}

div.pages button span {
    font-weight: bold;
    display: block;
    padding: 12px 24px;
}

div.pages button:before {
    content: "";

    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    width: 0;
    padding-top: 0;

    border-radius: 100%;

    background-color: rgba(236, 240, 241, .3);

    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

div.pages button:active:before {
    width: 120%;
    padding-top: 120%;

    transition: width .2s ease-out, padding-top .2s ease-out;
}

